/*Login Page*/
.login_page{
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}
.login_left{
  width: 55%;
  min-height: 100%;
  background: #F3FFED;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.login_right{
  width: 45%;
  min-height: 100%;
  background: #fff;
  padding: 75px;
  padding-bottom: 40px;
}
.login_left_bg {
  margin: auto;
}
.login_top-left{
  position: absolute;
  top: 0;
  left: 0;
}
.login_top-right{
  position: absolute;
  top: 0;
  right: 0;
}
.login_bottom-left{
  position: absolute;
  bottom: 0;
  left: 0;
}
.login_bottom-right{
  position: absolute;
  bottom: 0;
  right: 0;
}
.login_left p{
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 14px;
  color: #444444;
  padding-left: 40px;
}
.login_right img{
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}
.login_right h5 {
  color: #3DAB35;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 80px;
  text-align: center;
}
.login_right h4 {
  color: #101317;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  margin-bottom: 45px;
}
.login_right .login_form{
  width: 408px;
  margin: auto !important;
}
.login_right .login_form a{
text-decoration: none;
}
.login_right .login_form label{
  color: #444444;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
padding-left: 14px;
margin-bottom: 10px;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
}
.login_right .login_form .MuiInputBase-input{
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  color: #999999;
  font-size: 16px;
  line-height: 22px;
height: 48px;
}
.login_right .login_form .MuiInputBase-formControl{
width: 408px;
}
input:-internal-autofill-selected{
background-color: transparent !important;
}
.login_right .login_form .MuiTextField-root{
margin-bottom: 28px;
}
.login_right .login_form input::-webkit-input-placeholder{
color: #bbb;
opacity: 1;
}
.login_right .login_form input::-moz-placeholder{
color: #bbb;
opacity: 1;
}
.login_right .login_form input:-ms-input-placeholder{
color: #bbb;
opacity: 1;
}
.login_right .login_form input::-ms-input-placeholder{
color: #bbb;
opacity: 1;
}
.login_right .login_form input::placeholder{
color: #bbb;
opacity: 1;
}
.login_right .login_form img{
margin-bottom: 0;
}
input:-webkit-autofill {
-webkit-box-shadow: 0 0 0 30px white inset !important;
}
.login_right .login_form .MuiIconButton-root:hover {
background-color: transparent;
}
.login_new_password{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.login_new_password a{
  color: #65AF4B;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
text-decoration: none;
margin-top: 10px;
}
.login_right .login_form .login_submit{
  color: #FFFFFF;
font-size: 16px;
width: 100%;
background-color: #3DAB35;
text-transform: none;
margin-top: 50px;
margin-bottom: 100px;
}
.MuiRadio-colorSecondary.Mui-checked {
color: #3dab35 !important;
}
.MuiRadio-colorSecondary:hover {
background-color: rgb(61 171 53 / 0.04) !important;
}
.login_options {
width: 100%;
}
.login_options .MuiFormGroup-root{
-webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
        justify-content: flex-start;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
/* margin-bottom: 65px; */
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
    -ms-flex-direction: row;
        flex-direction: row;
}
.login_options .MuiFormGroup-root span.MuiTypography-body1{
font-size: 14px;
font-weight: 600;
color: #000000;
font-family: 'Open Sans', sans-serif;
}
.login_options label{
  margin-right: 30px;
}
.login_right p{
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #444444;
}
/*Login Page*/