.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: #3dab35 ;
    border-color: #3dab35 ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #3dab35 ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    font-size: small;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #3dab35 ;
    border-color: #3dab35;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #3dab35
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }

  .white-space {
    white-space:nowrap
  }