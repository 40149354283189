

.chartType {
    border:'1px solid #4CAF50';
    color: '#4CAF50';
    text-align: 'center';
}
.DialogTitle {
	font-weight: bold;
    align-self: center;
    margin-top: inherit;
	margin-bottom: inherit;
	padding-top: inherit;
    font-family: 'Open Sans', sans-serif;
}
.dialogSubtitle{
    margin-top: inherit;
    margin-bottom: 10px;
    padding-top: inherit;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
}
.sensorCheckbox {
    color: 'green';
    font-family: 'Open sans-serif'
}
.tfSelect {
    border:'1px solid #000000';
    color: '#000000';
    text-align: 'center';
    width:'inherit'
}

.deleteButton{
    border: 2px solid red;
    color: red;
    text-align: center;
    border-radius: 3px;
    text-transform: capitalize;
    margin-left: 10px;
    height: 44px;
    width: 103px;
}