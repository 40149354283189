
.App {
	text-align: center;
  }
  
  .App-logo {
	height: 40vmin;
	pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
	.App-logo {
	  -webkit-animation: App-logo-spin infinite 20s linear;
	          animation: App-logo-spin infinite 20s linear;
	}
  }
  
  .App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
  }
  .MuiDialogContent-root{
	  overflow-y: initial !important;
  }
  
  .App-link {
	color: #61dafb;
  }
  
  @-webkit-keyframes App-logo-spin {
	from {
	  -webkit-transform: rotate(0deg);
	          transform: rotate(0deg);
	}
	to {
	  -webkit-transform: rotate(360deg);
	          transform: rotate(360deg);
	}
  }
  
  @keyframes App-logo-spin {
	from {
	  -webkit-transform: rotate(0deg);
	          transform: rotate(0deg);
	}
	to {
	  -webkit-transform: rotate(360deg);
	          transform: rotate(360deg);
	}
  }
  
.swal-button-container {
	background: #ffffff !important;
}

.swal-button--confirm {
	background: #ffffff !important;
	font-family: 'Open Sans', sans-serif !important;
	color: #3dab35;
	border: 1px solid #3dab35;
}

.swal-button--danger {
	background: #ffffff !important;
	font-family: 'Open Sans', sans-serif !important;
	color: #3dab35;
	border: 1px solid #3dab35;
}

  .swal-button--cancel {
	background: #ffffff !important;
	font-family: 'Open Sans', sans-serif !important;
	border: 1px solid grey;
 }


.swal-icon  {
  border-color: #3DAB35 !important;
  font-family: 'Open Sans', sans-serif !important;
}

.swal-icon--error {
	background: #ffffff !important;
	font-family: 'Open Sans', sans-serif !important;
	color: #3dab35;
	border: 2px solid #f27474 !important;
}

.swal-icon--warning__body, .swal-icon--warning__dot {
	background-color: #3DAB35;
	font-family: 'Open Sans', sans-serif !important;
}

.swal-title {
	font-family: 'Open Sans', sans-serif !important;
	line-height: 40px !important;
}
/* .swal-modal{
	width: 589px !important;
} */

  /*Login Page*/
  
  *{
	-webkit-box-sizing: border-box;
			box-sizing: border-box;
	margin: 0;
	padding: 0;
  }
  body{
	  font-family: 'Open Sans', sans-serif;
  }
  .login_page{
	  width: 100%;
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  min-height: 100vh;
  }
  .login_left{
	  width: 55%;
	  min-height: 100%;
	  background: #F3FFED;
	  position: relative;
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-pack: center;
	      -ms-flex-pack: center;
	          justify-content: center;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	      -ms-flex-direction: column;
	          flex-direction: column;
  }
  .login_left_bg {
	  margin: auto;
	}
	.login_left_bg img{
		max-width: 500px;
	}
	.login_top-left{
		position: absolute;
		top: 0;
		left: 0;
	}
	.login_top-right{
		position: absolute;
	  top: 0;
	  right: 0;
  }
  .login_bottom-left{
	  position: absolute;
	  bottom: 0;
	  left: 0;
  }
  .login_bottom-right{
	  position: absolute;
	  bottom: 0;
	  right: 0;
  }
  .login_left p{
	  margin-bottom: 40px;
	  font-weight: 600;
	  font-size: 14px;
	  color: #444444;
	  padding-left: 40px;
	}
	.login_right{
		width: 45%;
		min-height: 100%;
		background: #fff;
		padding: 20px 75px;
	}
  .login_right img{
	  margin: auto;
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  margin-bottom: 20px;
  }
  .login_right h5 {
	  color: #3DAB35;
	  font-weight: 600;
	  font-size: 22px;
	  line-height: 30px;
	  margin-bottom: 40px;
	  text-align: center;
  }
  .login_right h4 {
	  color: #101317;
	  font-weight: 600;
	  font-size: 24px;
	  line-height: 20px;
	  margin-bottom: 25px;
  }
  .login_right .login_form{
	  width: 408px;
	  margin: auto !important;
  }
  .login_right .login_form a{
	text-decoration: none;
}
  .login_right .login_form label{
	  color: #444444;
	  font-weight: 600;
	  font-size: 16px;
	  line-height: 22px;
	padding-left: 14px;
	margin-bottom: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
  }
  .login_right .login_form .MuiInputBase-input{
	  -webkit-box-sizing: border-box;
	          box-sizing: border-box;
	  border-radius: 5px;
	  color: #999999;
	  font-size: 16px;
	  line-height: 22px;
	height: 48px;
  }
  .login_right .login_form .MuiInputBase-formControl{
	width: 408px;
  }
  input:-internal-autofill-selected{
	background-color: transparent !important;
  }
  .login_right .login_form .MuiTextField-root{
	margin-bottom: 28px;
  }
  .login_right .login_form input::-webkit-input-placeholder{
	color: #bbb;
	opacity: 1;
  }
  .login_right .login_form input::-moz-placeholder{
	color: #bbb;
	opacity: 1;
  }
  .login_right .login_form input:-ms-input-placeholder{
	color: #bbb;
	opacity: 1;
  }
  .login_right .login_form input::-ms-input-placeholder{
	color: #bbb;
	opacity: 1;
  }
  .login_right .login_form input::placeholder{
	color: #bbb;
	opacity: 1;
  }
  .login_right .login_form img{
	margin-bottom: 0;
  }
  input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  .login_right .login_form .MuiIconButton-root:hover {
	background-color: transparent;
  }
  .login_new_password{
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-pack: end;
	      -ms-flex-pack: end;
	          justify-content: flex-end;
  }
  .login_new_password a, .login_new_password span{
	  color: #65AF4B;
	  font-weight: 600;
	  font-size: 14px;
	  line-height: 19px;
	text-decoration: none;
	margin-top: 10px;
	cursor: pointer;
  }
  .login_right .login_form .login_submit{
	  color: #FFFFFF;
	font-size: 16px;
	width: 100%;
	background-color: #3DAB35;
	text-transform: none;
	margin-top: 20px;
    margin-bottom: 10px;
  }
  .MuiRadio-colorSecondary.Mui-checked {
	color: #3dab35 !important;
  }
  .MuiRadio-colorSecondary:hover {
	background-color: rgb(61 171 53 / 0.04) !important;
  }
  .login_options {
	width: 100%;
  }
  .login_options .MuiFormGroup-root{
	-webkit-box-pack: flex-start;
	    -ms-flex-pack: flex-start;
	        justify-content: flex-start;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	/* margin-bottom: 20px; */
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
  }
  .login_options .MuiFormGroup-root span.MuiTypography-body1{
	font-size: 14px;
	font-weight: 600;
	color: #000000;
	font-family: 'Open Sans', sans-serif;
  }
  .login_options label{
	  margin-right: 30px;
  }
  .login_right p{
	  font-weight: 600;
	  font-size: 13px;
	  line-height: 19px;
	  text-align: center;
	  color: #444444;
  }
  /*Login Page*/
  
  /*Dashboard Page*/
  
  .dashboard_header{
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-align: center;
	      -ms-flex-align: center;
	          align-items: center;
	  -webkit-box-pack: justify;
	      -ms-flex-pack: justify;
	          justify-content: space-between;
	  padding: 15px 50px;
	  background: #FFFFFF;
	  position: relative;
	  z-index: 9;
	  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
	          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  .dashboard_header_options button.MuiButton-containedPrimary{
	  background-color: transparent;
	  -webkit-box-shadow: none;
	          box-shadow: none;
	  text-transform: unset;
	  font-weight: 600;
	  font-size: 14px;
  }
  .dashboard_header_options button span{
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-align: center;
	      -ms-flex-align: center;
	          align-items: center;
	  font-weight: 600;
	  font-size: 14px;
	  color: #444444;
	  font-family: 'Open Sans', sans-serif;
  }
  .dashboard_header_options button span img{
	  margin: 0 5px;
  }
  .dashboard_footer{
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-align: center;
	      -ms-flex-align: center;
	          align-items: center;
	  -webkit-box-pack: justify;
	      -ms-flex-pack: justify;
	          justify-content: space-between;
	  padding: 14px 50px;
	  background: #FFFFFF;
	  -webkit-box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
	  box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
	  border-top: 1px solid #D1D1D1;
	  /* position: fixed; */
	  bottom: 0;
		left: 0;
		width: 100%;
  }
  .dashboard_footer p{
	  color: #0F0F0F;
	  letter-spacing: 0.222857px;
	  font-size: 12px;
	  line-height: 18px;
  }
  .dashboard_content{
	background: #F2F2F2;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
	min-height: calc(100vh - 118px);
}
.dashboard_left{
	width: 15%;
	padding: 30px 0;
	position: relative;
	background: #FBFBFB;
	-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
	        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.dashboard_left_toggle {
    position: absolute;
    right: -13px;
    top: 15px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.dashboard_left_toggle img {
    width: 25px;
	height: 25px;
	padding: 6px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #3dab35;
}
.dashboard_left_user{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	margin-bottom: 10px;
}
.dashboard_left_user_pic {
    width: 77px;
    height: 77px;
    border: 2px solid #3DAB35;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: #fff;
	-webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.304414);
	        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.304414);
	margin-bottom: 15px;
}
.dashboard_left_user_pic img{
	max-width: 50px;
}
.dashboard_left_user h5{
	color: #0F0F0F;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 5px;
	text-align: center;
}
.dashboard_left_user p{
	color: #636363;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
}
.dashboard_header_options_dropdown .MuiFormGroup-root span.MuiTypography-body1{
	font-size: 14px;
	font-weight: normal;
	color: #000000;
	font-family: 'Open Sans', sans-serif;
	padding-left: 10px;
  }
  .dashboard_header_options_dropdown .MuiSvgIcon-root{
	  width:19px;
	  height: 19px;
  }
  .dashboard_header_options_dropdown .MuiListItem-gutters{
	padding: 15px 25px;
	padding-left: 36px;
	width: 185px;
  }
  .dashboard_header_options_dropdown .PrivateSwitchBase-root-1, .dashboard_header_options_dropdown .MuiList-padding {
    padding: 0;
}
.dashboard_left_accordion {
    max-height: calc(100vh - 312px);
    overflow: auto;
}
.dashboard_left_accordion .MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
}
.dashboard_left_accordion .MuiAccordionSummary-content.Mui-expanded {
	margin: 12px 0;
	height: auto;
}
.dashboard_left_accordion .MuiAccordionSummary-content p, .dashboard_left_accordion .MuiListItem-gutters a{
	color: #000000;
	font-size: 12px;
	line-height: 18px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	text-align: left;
	text-decoration: none;
}
.dashboard_left_accordion .MuiAccordionSummary-content p img, .dashboard_left_accordion .dashboard_left_logout img, .dashboard_left_accordion .MuiListItem-gutters a img{
	margin-right: 16px;
}
.dashboard_left_accordion .dashboard_left_logout{
	width: 100%;
	padding: 12px 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	text-decoration: none;
	font-size: 12px;
	color: #000000;
}
.dashboard_left_accordion .dashboard_left_logout:hover{
	background-color: #e3f2e2;
}
.dashboard_left_accordion .MuiListItem-gutters a{
	padding: 8px 16px;
	padding-left: 52px;
	width: 100%;
	min-height: 48px;
}
.dashboard_left_accordion .MuiListItem-gutters a.active, .dashboard_left_accordion .MuiListItem-gutters a.active:hover, .dashboard_left_accordion .MuiAccordionSummary-content a:hover	{
	background-color: #3DAB35;
	color: #fff;
	font-weight: 600;
}
.dashboard_left_accordion .MuiListItem-gutters a:hover	{
	background-color: #e3f2e2;
}
.dashboard_left_accordion .MuiAccordionSummary-root, .dashboard_left_accordion .MuiAccordionSummary-root.Mui-expanded, .dashboard_left_logout{
	padding: 0px 30px;
	min-height: 48px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.dashboard_left_accordion .MuiIconButton-root{
	padding: 0 12px;
}
.dashboard_left_accordion .MuiAccordionDetails-root{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
}
.dashboard_left_accordion .MuiAccordionDetails-root .MuiList-padding{
	padding: 0;
    width: 100%;
}
.dashboard_left_accordion .MuiAccordionDetails-root .MuiListItem-gutters {
	min-height: 48px;
	padding: 0;
}
.dashboard_left_accordion .Mui-expanded .MuiListItem-gutters{
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.dashboard_left_accordion .MuiPaper-elevation1 {
	-webkit-box-shadow: none;
	        box-shadow: none;
	background-color: transparent;
}
.dashboard_left_accordion .MuiPaper-elevation1:nth-child(1) {
	border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.dashboard_left_accordion .MuiPaper-elevation1:last-child {
	border-bottom: 1px solid rgba(0, 0, 0, 0.18);
}
.dashboard_left_accordion .MuiPaper-elevation1:last-child .MuiAccordionSummary-root {
	border-bottom: 0;
}
.dashboard_left_accordion .MuiPaper-elevation1{
	border-radius: 0 !important;
}
.dashboard_right{
	width: 85%;
}
.dashboard_right .dashboard_top_path img{
	margin-right: 10px;
}
.dashboard_right .dashboard_top_path p{
	color: #101317;
	letter-spacing: 0.266667px;
	font-size: 16px;
	line-height: 22px;
}
.dashboard_right .dashboard_top_path p span{
	letter-spacing: 0.25098px;
	color: #3DAB35;
}
.dashboard_top_path{
	margin-bottom: 20px;
}
.dashboard_right_content{
	background: #FFFFFF;
	border-radius: 8px;
	padding: 20px 30px;
	position: relative;
	/* margin-top:-4% */
	/* height: calc(100vh - 900px); */
	/* height: calc(100vh - 437px); */
	/* height: 60px; */
/* overflow-y: auto; */
}
.schedule {
	margin-top:-4% 
}
.dashboard_right_content_top_icon{
	position: absolute;
	top: 0;
	left: 30px;
}
.dashboard_right_content_top{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	margin-bottom: 25px;
}
.dashboard_right_content_top h5{
	letter-spacing: 0.334286px;
	color: #3DAB35;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
}
.dashboard_right_content_top_options{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
			align-items: center;
	justify-content: flex-end;
}
.dashboard_right_content_top_options button span{
	color: #3DAB35;
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: small;
	line-height: 17px;
}
.dashboard_right_content_top_options button{
	text-transform: none;
	padding: 8px 15px;
	margin-left: 20px;
	border: 1px solid #3dab35;
}
.dashboard_right_content_top_options .emailMargin{
	margin-left: 0px !important; 
}
.dashboard_right_content_top_options .MuiButton-outlinedPrimary {
    border: 1px solid rgba(63, 171, 53, 0.5);
}
.dashboard_right_content_top_options .MuiButton-outlinedPrimary:hover {
    border: 1px solid #3dab35;
    background-color: rgba(63, 171, 53, 0.04);
}
.dashboard_right_content_top_search button{
	margin-left: 0;
	border: none;
}
.dashboard_right_content_top_search button.MuiButton-containedPrimary{
	border-radius: 0;
	background-color: #3dab35;
	margin-left: 0;
}
.dashboard_right_content_top_search button span{
	color: #fff;
	font-weight: normal;
	font-family: 'Open Sans', sans-serif;
	font-size: small;
}
.dashboard_right_content_top_search input{
	width: 150px;
	color: #888888;
	font-size: small;
	line-height: 16px;
	font-family: 'Open Sans', sans-serif;
}
.dashboard_right_content_top_search .MuiPaper-elevation1 {
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid #d6d5d5;
    border-radius: 3px;
    overflow: hidden;
	/* margin-top: 15px; */
	display: flex;
}
.dashboard_right_content_top_search .MuiIconButton-root:hover {
	background-color: transparent;
}
.dashboard_upload_bulkULBs_outer {
    padding: 0 30px;
    width: 540px;
    height: 366px;
}
.dashboard_upload_bulkULBs {
    width: 540px;
    height: 366px;
    border: 1px solid #d6d5d5;
	border-radius: 9px;
	margin-top: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	margin-bottom: 50px;
}
.dashboard_upload_bulkULBs img{
	margin-bottom: 30px;
}
.dashboard_upload_bulkULBs h4{
	margin-bottom: 40px;
	font-weight: 600;
	font-size: 20px;
	line-height: 27px;
	text-align: center;
	letter-spacing: 0.56px;
	color: #101317;
}
.dashboard_upload_bulkULBs button{
	background: #3DAB35;
	border: 1px solid #3DAB35;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	border-radius: 4px;
	width: 198px;
	height: 42px;
	text-transform: none;
}
.dashboard_upload_bulkULBs button.MuiButton-containedPrimary:hover {
    background-color: #3DAB35;
}
.dashboard_upload_bulkULBs_popup .MuiDialog-paperWidthSm {
    max-width: 640px;
	width: 640px;
	max-height: 500px;
    height: 480px;
}
.dashboard_listing thead th{
	color: #101317;
	font-weight: 600;
	font-size: 12px;
	line-height: 17px;
	padding-bottom: 10px;
	padding-left: 0px;
	padding-right: 0px;
}
/* .dashboard_listing .MuiTableCell-root{
	font-family: 'Open Sans', sans-serif;
} */
.dashboard_listing tbody td{
	color: #444444;
	font-size: 12px;
	line-height: 14px;
	padding: 4px 16px;
}
.dashboard_listing table{
	border-collapse: separate;
	border-spacing: 0 6px;
}
.dashboard_listing .MuiPaper-elevation1{
	-webkit-box-shadow: none;
	        box-shadow: none;
}
.dashboard_listing .MuiTableCell-root{
	border-bottom: 0;
	/* padding-left: 1%; */
	padding-right: 1% !important;
	font-family: 'Open Sans', sans-serif;
	/* height: ; */
}
/* .AreaLIsting .MuiTableCell-root{
	padding: 14px !important;
} */
.dashboard_listing tbody tr td.MuiTableCell-root{
	border-top: 1px solid #E3EAED;
	border-bottom: 1px solid #E3EAED;
}
.dashboard_listing tbody tr td:nth-child(1){
	border-left: 1px solid #E3EAED;
	border-radius: 5px 0 0 5px;
	/* padding: 15px; */
}
.dashboard_listing tbody tr td:last-child{
	border-right: 1px solid #E3EAED;
	border-radius: 0 5px 5px 0;
}
.dashboard_table_dropdown li{
	padding: 0;
}
.dashboard_table_dropdown button{
	font-family: 'Open Sans', sans-serif;
	font-weight: normal;
	padding: 9px 20px;
	font-size: 14px;
	line-height: 17px;
	color: #0F0F0F;
	width: 100px;
	text-transform: none;
	display: block;
    text-align: left;
}
.dashboard_table_dropdown .MuiList-padding{
	padding: 0;
}
 
  /*Dashboard Page*/


  /* Create New ULB */

  .create_ulb_content_outer{
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-pack: center;
	      -ms-flex-pack: center;
	          justify-content: center;
  }
  .create_ulb_content{
	font-size: small;
	width: 866px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify; 
	        justify-content: space-between;
  }
  .create_ulb_content .MuiFormControl-root{
	  font-size: small;
	  width: 98%;
	  margin-bottom: 25px;
  }
  .create_ulb_content_sched .MuiFormControl-root{
	font-size: small;
	width: 98%;

}
  .create_ulb_content .MuiTextField-root{
	  font-size: small;
	  margin-bottom: 0;
  }
  .create_ulb_content label{
	font-size: small;
	font-weight: 600;
	/* font-size: 16px; */
	line-height: 22px;
	color: #444444;
	margin-bottom: 10px;
	/* padding-left: 14px; */
	display: flex;
  }
  .create_ulb_content .MuiOutlinedInput-input {
	font-size: small;
    padding: 10px 14px;
    height: 46px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
  /* .create_ulb_content label span {
	color: #FF3030;
  } */
  .create_ulb_content .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3dab35;
    border-width: 1px;
}
.spanLable{
	color: #3dab35 !important;
}
  .create_ulb_content .MuiInput-underline:before {
    border: 1px solid #c4c4c4;
    width: 100%;
	height: 100%;
	border-radius: 3px;
}
  .create_ulb_content .MuiSelect-selectMenu {
	font-size: small;
	height: 46px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
	padding-left: 14px;
	color: #b1b0b0;
  }
  .create_ulb_content .MuiInput-underline:hover:not(.Mui-disabled):before, .create_ulb_content .MuiInput-underline:after {
    border-bottom: 2px solid #3dab35 !important;
  }
  .create_ulb_content .MuiSelect-select:focus{
	  background-color: #fff;
  }
  .create_ulb_content label + .MuiInput-formControl {
    margin-top: 0;
	width: 98%;
	}
	.create_ulb_buttons {
		width: 100%;
		text-align: center;
		margin-top: 10px;
		margin-bottom: 80px;
	}
	.create_ulb_buttons button{
		margin: 0 17px;
		width: 200px;
		height: 46px;
		text-transform: unset;
	}
	.create_ulb_buttons button span{
		font-size: small;
		line-height: 22px;
		font-family: 'Open Sans', sans-serif;
	}
	.create_ulb_buttons .MuiButton-containedPrimary, .create_ulb_buttons .MuiButton-containedPrimary:hover {
		color: #fff;
		background-color: #3dab35;
	}
	.create_ulb_buttons .MuiButton-outlinedPrimary {
		color: #3dab35;
		border: 1px solid rgba(61, 171, 53, 0.5);
	}
	.create_ulb_buttons .MuiButton-outlinedPrimary:hover {
		border: 1px solid #3dab35;
		background-color: rgba(61, 171, 53, 0.04);
	}
	
	.email_link {
		text-decoration: none;
		color: #3DAB35;
		font-family: 'Open Sans', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: small;
		line-height: 17px;
	}

	.email_bitton_remove {
		border: 1px solid #3DAB35;
		border-radius: 2px;
		padding: 8px;
		color: #3DAB35;
		font-family: 'Open Sans', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: small;
		background: none;
	}
	.email_bitton_save {
		border: 1px solid #3DAB35;
		border-radius: 2px;
		padding: 5px;
		color: #3DAB35;
		font-family: 'Open Sans', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		background: none;
	}
	.required:after {
		padding-left: 1%;
		content:"*";
		color: red;
	}
  /* Create New ULB */

/* Record Keeping/ Loads */

.record_keeping_email_listing thead th{
	color: #101317;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	padding-bottom: 10px;
}
.record_keeping_email_listing .MuiTableCell-root{
	font-family: 'Open Sans', sans-serif;
}
.record_keeping_email_listing tbody td{
	color: #444444;
	font-size: 12px;
	line-height: 14px;
	padding: 8px 16px;
}
.record_keeping_email_listing table{
	border-collapse: separate;
	border-spacing: 0 15px;
	padding-left: 15px;
	padding-right: 15px; 
}
.record_keeping_email_listing .MuiPaper-elevation1{
	-webkit-box-shadow: none;
	        box-shadow: none;
}
.record_keeping_email_listing .MuiTableCell-root{
	border-bottom: 0;
}
.record_keeping_email_listing tbody tr td.MuiTableCell-root{
	border-top: 1px solid #E3EAED;
	border-bottom: 1px solid #E3EAED;
}
.record_keeping_email_listing tbody tr td:nth-child(1){
	border-left: 1px solid #E3EAED;
	border-radius: 5px 0 0 5px;
}
.record_keeping_email_listing tbody tr td:last-child{
	border-right: 1px solid #E3EAED;
	border-radius: 0 5px 5px 0;
}

.record_keeping_email_placeholder {
	font-family: 'Open Sans', sans-serif !important;
	font-size:12px;
}

/* Record Keeping/ Loads */

.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
      z-index: 1;
    }
  }



/* Responsive CSS */

/* @media only screen and (max-device-width : 768px) {
    html,body { display: none; } 
} */

@media only screen and (min-width: 1001px) and (max-width:1300px) {

	.login_left_bg img {
		width: 90%;
		margin: auto;
		display: flex;
	}
	.login_right {
		padding: 20px 55px;
	}
	.login_right .login_form .MuiInputBase-formControl, .login_right .login_form {
		width: 300px;
	}
	.dashboard_right_content_top_search input{
		width: 100px;
	}
	.dashboard_right_content_top_options button{
		padding: 8px;
		margin-left: 10px;
		min-width: 40px;
	}
	.dashboard_right_content_top_options button span{
		font-size: 12px;
	}
	.dashboard_right_content_top_search button {
		margin-left: 0;
	}
	.dashboard_right_content_top h5{
		font-size: 16px;
	}
	/* .create_ulb_content .MuiFormControl-root {
		width: 290px;
	} */
}


/* Responsive CSS */

.react-time-picker__wrapper {
	border-radius: 4px;
	border: thin solid #c5c5c5;
	height : 46px
}
.react-time-picker__inputGroup {
	font-family: 'Open Sans', sans-serif !important;
	font-size: small;
	
 
}

 .date-input-field{
	height:35px;
	border-radius:5px;
	font-family: 'Open Sans', sans-serif !important;
	border: thin solid #c5c5c5;
	background-color: white;
	
 }
 /* .ReactTable{
	background-color: white;
	position: fixed;
	top: 1;
	z-index: 1;
	width: calc(100% - 17px);
	height: 31px;
  } */

  /*operator
  */
  .operator_listing thead th{
	color: #101317;
	font-weight: 600;
	font-size: 12px;
	line-height: 17px;
	padding-bottom: 10px;
	padding-left: 0px;
	padding-right: 0px;
}
.operator_listing .MuiTableCell-root{
	font-family: 'Open Sans', sans-serif;
}
.operator_listing tbody td{
	color: #444444;
	font-size: 12px;
	line-height: 14px;
	padding:4px 
}
.operator_listing table{
	border-collapse: separate;
	border-spacing: 0 6px;
}
.operator_listing .MuiPaper-elevation1{
	-webkit-box-shadow: none;
	        box-shadow: none;
}
.operator_listing .MuiTableCell-root{
	border-bottom: 0;
	padding-left: 1%;
	/* padding-right: 1%; */
}

.operator_listing tbody tr td.MuiTableCell-root{
	border-top: 1px solid #E3EAED;
	border-bottom: 1px solid #E3EAED;
}
.operator_listing tbody tr td:nth-child(1){
	border-left: 1px solid #E3EAED;
	border-radius: 5px 0 0 5px;
	/* padding: 15px; */
}
.operator_listing tbody tr td:last-child{
	border-right: 1px solid #E3EAED;
	border-radius: 0 5px 5px 0;
}
.fileContainer{
	box-shadow: none !important;
	padding:0px !important;
	margin:0px !important;
	
}
.fileContainer .deleteImage{
	background: #3dab35 !important;
	font-size: small !important;
	line-height: 15px !important;
	width: 15px !important;
	height: 15px !important
	/* color: #3dab35; */
}
.fileContainer .chooseFileButton{
	padding: 0px 15px
}

.MuiButton-label {
	font-size: small;
}
.MuiPickersToolbar-toolbar{
	background-color: #3dab35 !important;
}
.MuiButton-textPrimary{
	color:#3dab35 !important
}
.MuiPickersClock-pin{ 
	background-color: #3dab35 !important;
}
.MuiPickersClockPointer-thumb{
	border: 14px solid #43a047 !important;
}
.MuiPickersClockPointer-animateTransform{
	background-color: #3dab35 !important;
}
.MuiPickersClockNumber-clockNumberSelected{
	background-color: #3dab35 !important;
}
.MuiPickersClockPointer-pointer {
	background-color: #3dab35 !important;

}
.MuiPickersDay-daySelected{
	background-color: #3dab35 !important;
}
.MuiPickersDay-current{
	color:rgba(0, 0, 0, 0.87) !important;
}
.MuiMenuItem-root {
	font-size: small !important;
	font-family: 'Open Sans', sans-serif !important;
	min-height: 35px !important;
	padding-bottom: 0%;
}
.MuiPickersDay-daySelected{
	background-color: #3dab35 !important;
}
/* .MuiInput-underline:before{
	border-bottom: none !important
} */
.MuiInput-underline:after{
	border-bottom: #3dab35 !important;
}
.MuiPickersDay-current {
	color: black !important;
}
.MuiInputBase-root{
	font-size: 12px !important;
}
.MuiSvgIcon-root{
	font-size: small !important;
}
/* .fileContainer{
	     flex-direction: row !important;
}
.fileContainer .uploadPictureContainer {
	width: 8% !important;
	margin: 0px !important;
	padding:0px !important
} */

.MuiTypography-h6 {
	border-style: solid;
    border-width: thin;
	border-radius: 3px;
	text-decoration-color:  #3dab35;
	/* border-color: #3DAB35; */
	font-family: 'Open Sans', sans-serif;
	
}

.border-none .MuiTypography-h6{
	border: none !important;
    text-align: center;
	font-weight:700 !important
}
.MuiInputBase-root .MuiOutlinedInput-root .MuiOutlinedInput-root .Mui-focused .Mui-focused.MuiInputBase-formControl{
	border-color: #3DAB35;
}
.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-formControl{
	border-color: #3DAB35;
}

.MuiInput-underline:before{
	border-bottom:none;
}
.MuiInput-underline:after{
	border-bottom: none;
}

.imgButtonEnable{
	font-family: 'Open Sans', sans-serif !important;
	text-transform: none !important;
	color:#3dab35 !important;
    /* padding: 8px 15px; */
    margin-left: 20px;
	border: 1px solid #3dab35 !important;
	border-radius: 3px;
}
.imgButtonDisable{
	font-family: 'Open Sans', sans-serif !important;
	font-size:'5px';
	text-transform: none !important;
	color:#3dab35 !important;
    /* padding: 8px 15px; */
    margin-left: 20px;
	border-radius: 3px;
	border: 1px solid rgba(0, 0, 0, 0.12) !important
}
/*truck doc*/
.truckdoc_listing thead th{
	color: #101317;
	font-weight: 600;
	font-size: 12px;
	line-height: 17px;
	padding-bottom: 10px;
	padding-left: 0px;
	padding-right: 0px;
}
.truckdoc_listing .MuiTableCell-root{
	font-family: 'Open Sans', sans-serif;
}
.truckdoc_listing tbody td{
	color: #444444;
	font-size: 12px;
	line-height: 37px;
	padding:4px 
}
.truckdoc_listing table{
	border-collapse: separate;
	border-spacing: 0 15px;
}
.truckdoc_listing .MuiPaper-elevation1{
	-webkit-box-shadow: none;
	        box-shadow: none;
}
.truckdoc_listing .MuiTableCell-root{
	border-bottom: 0;
	padding-left: 1%;
	/* padding-right: 1%; */
}

.truckdoc_listing tbody tr td.MuiTableCell-root{
	border-top: 1px solid #E3EAED;
	border-bottom: 1px solid #E3EAED;
}
.truckdoc_listing tbody tr td:nth-child(1){
	border-left: 1px solid #E3EAED;
	border-radius: 5px 0 0 5px;
	/* padding: 15px; */
}
.truckdoc_listing tbody tr td:last-child{
	border-right: 1px solid #E3EAED;
	border-radius: 0 5px 5px 0;
}
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
	background-color:#bbe6b0 !important;
}
.MuiListItem-root.Mui-selected:hover {
	background-color:#bbe6b0 !important;
}
.MuiListItem-root {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	padding-left: 31px !important;
}
.MuiList-padding{
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
.AccordionStyle{
	background-color:#bbe6b0 !important;
}
.MuiSlider-root {
	color:#3dab35 !important;
	padding: 23px 0 !important;
}

.MuiFormControl-root {
	width: 100%;
}
.MuiInput-input{
	 /* height: 30px !important; */
	 padding: 10px 14px !important;
}
.MuiSlider-thumb {
	transform: scale(0.77) !important;
}
.MuiSlider-marked {
    margin-bottom: 0px !important;
}
.ulb_listing thead th{
	color: #101317;
	font-weight: 600;
	font-size: 12px;
	line-height: 17px;
	padding-bottom: 10px;
	padding-left: 0px;
	padding-right: 0px;
}
.ulb_listing  .MuiTableCell-root{
	font-family: 'Open Sans', sans-serif;
}
.ulb_listing  tbody td{
	color: #444444;
	font-size: 12px;
	/* line-height: 34px;
	padding:4px  */
}
.ulb_listing  table{
	border-collapse: separate;
	border-spacing: 0 15px;
}
.ulb_listing  .MuiPaper-elevation1{
	-webkit-box-shadow: none;
	        box-shadow: none;
}
.ulb_listing  .MuiTableCell-root{
	border-bottom: 0;
	padding-left: 1%;
	/* padding-right: 1%; */
}

.ulb_listing  tbody tr td.MuiTableCell-root{
	border-top: 1px solid #E3EAED;
	border-bottom: 1px solid #E3EAED;
}
.ulb_listing  tbody tr td:nth-child(1){
	border-left: 1px solid #E3EAED;
	border-radius: 5px 0 0 5px;
	/* padding: 15px; */
}
.ulb_listing  tbody tr td:last-child{
	border-right: 1px solid #E3EAED;
	border-radius: 0 5px 5px 0;
}
.button_link {
	text-decoration: none;
	color: #3DAB35 !important;
	font-family: 'Open Sans', sans-serif !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: small !important;
	line-height: 17px !important;
	border: 1px solid #3DAB35  !important;
	margin-right: 5px !important
}

.emailEdit{
	color: #ff7c30 !important;
	border: 1px solid !important;
	font-size: x-small !important;
	border-radius: 0% !important;
	text-transform: capitalize !important;
	padding: 1px 15px !important;
}
.emailDelete{
	color: #FF3030 !important;
	border: 1px solid !important;
	font-size: x-small !important;
	margin-left: 40px !important;
	border-radius: 0% !important;
	text-transform: capitalize !important;
	padding: 1px 15px !important;
}
.spanLable .MuiTypography-body1{
	font-size: 15px !important;
}
.spanLable .MuiSvgIcon-root {
    font-size: 17px !important;
}
.MuiSlider-markLabel{
	top: 45px !important;
}


.MuiSlider-mark{
	display: none;
}
span [class^="PrivateValueLabel-circle"] {
	border-radius: 4% !important;
transform: none !important;
background-color: #fff !important;

	border: 2px solid !important;
	width: 40px;
	font-weight: 700;
}
span [class^="PrivateValueLabel-label"]{
	color: #3DAB35 !important;
	transform: none !important;
	font-size: 16px;
}
.MuiFormControl-marginNormal {
    margin-top: 0 !important;
}


.AreaLIsting .MuiTableCell-root{
	padding-left: 1% !important;
}.valueOf-para{
	padding: 20px 20px 20px 0;
    text-transform: capitalize;
	/* text-align: center; */
}
.valueOf-Throhold{
	padding: 20px 20px 20px 0;
    text-transform: uppercase;
	text-align: center;
}
/* .MuiDialog-paperWidthSm {
    max-width: none !important;
    width: 50%;
} */
#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:#3DAB35;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

@media screen and (min-width: 992px) and (max-height: 1310px) {
	.create-Rd .MuiFormControl-root {
		/* margin-top: -24px !important; */
		width: 100%;
		/* margin-left: 30px; */
	}
}
.dipalyApp{
	display: none;
}
.desktopDisplay{
	display: block;
}
.mobileDisplay{
	display: none;
}
.mobile-load-record{
	display: none;
}
@media only screen and (max-width:480px) {
	html,body
    {
        overflow-x:hidden !important;
    }
	.dashboard_page{
		display: none;
	}
	.dashboard_header{
		box-shadow:none !important;
		padding-right: 0% !important;
		padding-left: 0% !important;
	}
	.dashboard_right_contentWithout_bg{
		margin-top: 45px;
	}
	.desktopDisplay{
		display: none;
	}
	.mobileDisplay{
		display: block;
	}
	.dashboard_header img {
		width: 25%;
	}
	.iconbuttong{
		padding-left:215px !important;
	}
	.dashboard_left{
		width: 100%;
		box-shadow: none !important;
		background-color: white !important;
	}
	.mobile-size-sd{
		margin-top: 85px;
	}
	.dipalyApp{
		display: block;
	}
	.dashboard_right {
		width: 100%;
	}
	.dashboard_right_content{
		display: none !important;
	}
	/* .dashboard_listing{
		margin-top: 20px;
	} */
.dashboard_header_options{
	margin-left: 127px;
}
.MuiTableCell-stickyHeader{
	left: unset !important;
}
.dashboard_footer{
	display:none
	/* padding: 14px 19px !important; */
}
/* .dashboard_header_options  */
.table-height-state{
	height: auto;
	padding-bottom: 68px !important;
}
.dateTop{
	margin-top: -16px !important;
}
.heading-filter{
 margin-left:0 !important;
 font-size: 17px !important;
}
.paramDiv{
	padding: 0% 6% !important;
}
.create_ulb_content .MuiSelect-selectMenu{
	height: 36px !important;
}
.second-ulb h2{
	font-size: 17px !important;
	margin-left: 0% !important;
}
.second-ulb .MuiInputBase-root{
	margin-top: -8px !important;
} 
.py-mob{
	padding-top: 10px !important;
    padding-bottom: 20px !important;
    width: 100% !important;
	padding-left: 15px;
}
.second-ulb{
	margin-top: 1px;
}
.style-slect-ulb{
	width:100%;
}
.grid-Padding{
	padding-left: 12px !important;
	/* padding-right: -1px !important; */
}

.table-height{
	height: 600px !important;
	overflow:hidden !important;
}
.create_ulb_contentPop .MuiFormControl-root{
	font-size: small;
	width: 98%;
	margin-bottom: 2px !important;
}
  .create_ulb_content_sched .MuiFormControl-root{
	font-size: small;
	width: 98%;

}
/* .marLeft{
	margin-top: 15px !important;
} */
.mt-top{
	margin-top: 7% !important;
}
.table-paper{
	width: 100% !important;
}
.dashboard_right_content_top{
	margin-bottom: 10px ;
}
#table-c{
	height: 400px;
}
.dashboard_top_path{
	margin-left: 50px;
}
/* login */
.login_page{
	flex-direction: column !important;
}
.login_left{
	width: 100% !important;
}
.login_left_bg img {
    max-width: 100% !important;
}

.login_right{
	width: 100% !important;
	padding: 20px !important;
}

.login_right .login_form {
    width: 100% !important;
    margin: auto !important;
}

.login_right .login_form .MuiInputBase-formControl {
    width: 100% !important;
}
.login_top-left {
	width:20% 
}
.login_bottom-right {
	width:20% 
}
/* load record */
.mobile-load-record{
	display: block;
	margin-top: 40px;
	padding: 10px;
}
.dashboard_right_content_top_options button{
	padding: 10px 36px !important;
	margin-left: 0;
}
.scroltable-mobile{
	height: 400px;
    overflow: scroll;
}
.dashboard_right_content_top_search input{
	width: 100%;
}
}
.MuiSlider-root {
	color:#3dab35 !important;
	padding: 23px 0 !important;
}

.MuiFormControl-root {
	width: 100%;
}
.MuiInput-input{
	 /* height: 30px !important; */
	 padding: 10px 14px !important;
}
.MuiSlider-thumb {
	transform: scale(0.77) !important;
}
.MuiSlider-marked {
    margin-bottom: 0px !important;
}
.ulb_listing thead th{
	color: #101317;
	font-weight: 600;
	font-size: 12px;
	line-height: 17px;
	padding-bottom: 10px;
	padding-left: 0px;
	padding-right: 0px;
}
.ulb_listing  .MuiTableCell-root{
	font-family: 'Open Sans', sans-serif;
}
.ulb_listing  tbody td{
	color: #444444;
	font-size: 12px;
	line-height: 34px;
	padding:4px 
}
.ulb_listing  table{
	border-collapse: separate;
	border-spacing: 0 6px;
}
.ulb_listing  .MuiPaper-elevation1{
	-webkit-box-shadow: none;
	        box-shadow: none;
}
.ulb_listing  .MuiTableCell-root{
	border-bottom: 0;
	padding-left: 1%;
	/* padding-right: 1%; */
}

.ulb_listing  tbody tr td.MuiTableCell-root{
	border-top: 1px solid #E3EAED;
	border-bottom: 1px solid #E3EAED;
}
.ulb_listing  tbody tr td:nth-child(1){
	border-left: 1px solid #E3EAED;
	border-radius: 5px 0 0 5px;
	/* padding: 15px; */
}
.ulb_listing  tbody tr td:last-child{
	border-right: 1px solid #E3EAED;
	border-radius: 0 5px 5px 0;
}
.button_link {
	text-decoration: none;
	color: #3DAB35 !important;
	font-family: 'Open Sans', sans-serif !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: small !important;
	line-height: 17px !important;
	border: 1px solid #3DAB35  !important;
	margin-right: 5px !important
}

.operatorDetailsValue{
	color: #3DAB35;
    font-size: 12px;
	font-weight: 600;
	margin-left: 4px;
}

@media only screen and (width:375px){
	.scroltable-mobile {
		height: 342px;
	}
}

.textNode-content{
	line-height: 35px;
    white-space: pre-line;
	font-size: larger !important;
	font-family: 'Open Sans', sans-serif !important;
	text-align: left !important;
}
.helpTxt .MuiFormHelperText-root.Mui-error{
	margin-bottom: -41px;
}

.selectCheckboxStatus .MuiCheckbox-colorSecondary.Mui-checked{
color: #3dab35 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked{
	color: #3dab35 !important;
}
	
#selectUlb{
	height: 30px !important;
}

.card-text-style {
margin-top: 15% !important;
}
.swal-back-edit .swal-text{
	color: red !important;
}

.roleMOdule .create_ulb_content .MuiOutlinedInput-input{
	height: 0px !important;
}

.roleMOdule .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child{

    padding: 15px 0 !important;
}


.scrollPrent{
    width: 100%;
    height: 100%;
    overflow: hidden !important;
}

.hidescroll{
 
    height: 150px;
    overflow-y: scroll;
    
    box-sizing: content-box; 
}
/* .scrollPrent ::-webkit-scrollbar {
    display: none !important;
} */

.scrollPrentClu{
	width: 100%;
    height: 100px;
    overflow: auto !important;
}
.scrollPrentClu ::-webkit-scrollbar {
    display: none !important;
}
.scrollPrent .MuiTableContainer-root{
	height: auto;
}

.tale{
	width: 100%;
    height: 100%;
    /* overflow: hidden !important; */
}
.table-sc-style{
	    /* width: 100%; */
		height: 150px;
		overflow-x: scroll;
		/* padding-right: 17px; Increase/decrease this value for cross-browser compatibility */
		box-sizing: content-box; /* So the width will be 100% + 17px */
}
/* .tale ::-webkit-scrollbar {
    display: none !important;
} */

.confirmationAlert .swal-text{
	padding: 0 60px !important;
}
.but-config span{
	color: #ffffff;
}

.table-scroll-property{
	width:100%;
	height:300px;
	overflow-x:scroll;
	
}

.root1 {
	flex-grow: 3;
	width: '100%';
	/* background-color: "white"; */
  }
  .paper{
	box-shadow: none !important;
  }


  .tabs-setting span{
	color: green !important;
	font-weight: 800;
  }

  /* .tabs-setting .MuiTab-textColorInherit.Mui-selected{
	background-color: #3DAB35 !important;

  } */

  .check-class .MuiCheckbox-colorSecondary.Mui-checked{
	color: green !important;
}
/* .check-class{
	margin-left: 18px;
} */
.icon-number{
	color: #3dab35;
	font-size: 33px;
	min-height: 5px;
	font-weight: 1000;
	margin-bottom: 10px;
	word-wrap:normal;

}
.icon-word{
	color: black;
	font-size: 20px;
	word-wrap:normal
}

@media only screen and (min-width:1100px) and (max-width:1200px) {

	.icon-number{
		color: #3dab35;
		font-size: 45px;
		min-height: 5px;
		font-weight: 1000;
		margin-bottom: 10px;
		word-wrap:normal;
	

	}
	.icon-word{
		color: black;
		font-size: 30px;
		word-wrap:normal
	}
}

@media only screen and (min-width:1200px) and (max-width:1350px) {

	.icon-number{
		color: #3dab35;
		font-size: 35px;
		min-height: 5px;
		font-weight: 1000;
		margin-bottom: 10px;
		word-wrap:normal;
	

	}
	.icon-word{
		color: black;
		font-size: 18px;
		word-wrap:normal
	}
}
@media only screen and (min-height:500px) and (max-height:610px) {
	.scrollSummaryPage .MuiTableContainer-root{
		height: 35vh;
	}
	.scrollScheduleProperty .MuiTableContainer-root{
		height: 52vh;
	}
	.scrollOperator .MuiTableContainer-root{
		height: 38vh;
	}
	.scrollward .MuiTableContainer-root{
		height: 51vh;
	}
	.scrollULB .MuiTableContainer-root{
		height: 51vh;
	}
	.scrolluser .MuiTableContainer-root{
		height: 51vh;
	}

}
@media only screen and (min-height:611px) and (max-height:700px) {
	.scrollSummaryPage .MuiTableContainer-root{
		height: 35vh;
	}
	.scrollScheduleProperty .MuiTableContainer-root{
		height: 54vh;
	}
	.scrollOperator .MuiTableContainer-root{
		height: 41vh;
	}
	.scrollward .MuiTableContainer-root{
		height: 54vh;
	}
	.scrollULB .MuiTableContainer-root{
		height: 54vh;
	}
	.scrolluser .MuiTableContainer-root{
		height: 54vh;
	}

}
@media only screen and (min-height:701px) and (max-height:800px) {
	.scrollSummaryPage .MuiTableContainer-root{
		height: 40vh;
	}
	.scrollScheduleProperty .MuiTableContainer-root{
		height: 62vh;
	}
	.scrollOperator .MuiTableContainer-root{
		height: 48vh;
	}
	.scrollward .MuiTableContainer-root{
		height: 63vh;
	}
	.scrollULB .MuiTableContainer-root{
		height: 63vh;
	}
	.scrolluser .MuiTableContainer-root{
		height: 63vh;
	}

}
@media only screen and (min-height:801px) and (max-height:900px) {
	.scrollSummaryPage .MuiTableContainer-root{
		height: 44vh;
	}
	.scrollScheduleProperty .MuiTableContainer-root{
		height: 65vh;
	}
	.scrollOperator .MuiTableContainer-root{
		height: 54vh;
	}
	.scrollward .MuiTableContainer-root{
		height: 65vh;
	}
	.scrollULB .MuiTableContainer-root{
		height: 65vh;
	}
	.scrolluser .MuiTableContainer-root{
		height: 66vh;
	}
}
@media only screen and (min-height:901px) and (max-height:1000px) {
	.scrollSummaryPage .MuiTableContainer-root{
		height: 50vh;
	}
	.scrollScheduleProperty .MuiTableContainer-root{
		height: 69vh;
	}
	.scrollOperator .MuiTableContainer-root{
		height: 59vh;
	}
	.scrollward .MuiTableContainer-root{
		height: 69vh;
	}
	.scrollULB .MuiTableContainer-root{
		height: 69vh;
	}
	.scrolluser .MuiTableContainer-root{
		height: 69vh;
	}
}
@media only screen and (min-height:1001px) and (max-height:1100px) {
	.scrollSummaryPage .MuiTableContainer-root{
		height: 58vh;
	}
	.scrollScheduleProperty .MuiTableContainer-root{
		height: 72vh;
	}
	.scrollOperator .MuiTableContainer-root{
		height: 63vh;
	}
	.scrollward .MuiTableContainer-root{
		height: 72vh;
	}
	.scrollULB .MuiTableContainer-root{
		height: 72vh;
	}
	.scrolluser .MuiTableContainer-root{
		height: 72vh;
	}
}
@media only screen and (min-height:1101px)  {
	.scrollSummaryPage .MuiTableContainer-root{
		height: 60vh;
	}
	.scrollScheduleProperty .MuiTableContainer-root{
		height: 74vh;
	}
	.scrollOperator .MuiTableContainer-root{
		height: 65vh;
	}
	.scrollward .MuiTableContainer-root{
		height: 74vh;
	}
	.scrollULB .MuiTableContainer-root{
		height: 74vh;
	}
	.scrolluser .MuiTableContainer-root{
		height: 74vh;
	}
}


.rootBuffer .MuiLinearProgress-buffer {
    background-color: darkseagreen !important;
}
.rootBuffer .MuiLinearProgress-colorPrimary {
    background-color: green  !important;
}

.chip{
		display: flex;
		justify-content: 'center';
		flex-wrap: wrap;
		list-style: none;
		padding: 10px;
		margin: 0;
		height:150px;
		overflow-y:scroll;
		
	
}