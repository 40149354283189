.chartType {
    border:'1px solid #4CAF50';
    color: '#4CAF50';
    text-align: 'center';
}
.DialogTitle {
	font-weight: bold;
    align-self: center;
    margin-top: inherit;
	margin-bottom: inherit;
	padding-top: inherit;
    font-family: 'Open Sans', sans-serif;
}
.sensorCheckbox {
    color: 'green';
    font-family: 'Open sans-serif'
}
.tfSelect {
    border:'1px solid #000000';
    color: '#000000';
    text-align: 'center';
    width:'inherit'
}
.DialogSubtitle {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
